import { AlertOutlined, EnvironmentOutlined, HomeOutlined } from '@ant-design/icons';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { Main } from 'src/layouts/main';
import { RoutePathDefinition } from 'src/lib/routing';

const BigscreenPage = lazy(() => import('src/containers/bigscreen/bigscreen.page'));
const OverviewPage = lazy(() => import('src/containers/overview/overview.page'));
const MonitorPage = lazy(() => import('src/containers/monitor/monitor.page'));

export const mainRoute: RoutePathDefinition = {
  path: '/',
  element: <Main />,
  children: [
    { index: true, element: <Navigate to="/bigscreen" replace /> },
    {
      title: '可视化大屏',
      path: 'bigscreen',
      element: <BigscreenPage />,
      icon: <HomeOutlined />,
    },
    {
      title: '服务概览',
      path: 'overview',
      element: <OverviewPage />,
      icon: <HomeOutlined />,
    },
    {
      title: '地图监控',
      path: 'map',
      element: <MonitorPage />,
      icon: <EnvironmentOutlined />,
    },
    {
      title: '报警记录',
      path: 'alert',
      element: <OverviewPage />,
      icon: <AlertOutlined />,
      hidden: true,
    },
  ],
};

export const staticRoutes: RoutePathDefinition[] = [mainRoute];
