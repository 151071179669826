// @ts-ignore
import { snakeCase, toUpper } from 'lodash';

export const upperSnakeCase = (str: string) => toUpper(snakeCase(str));

export const withSuffix = (suffix: string | number) => (str: any) => `${str}${suffix}`;

export const safeJSONParse = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return undefined;
  }
};

export const checkEmpty = (obj: any) => typeof obj === 'undefined' || obj === null || obj === '';

/**
 * 拼接对象为请求字符串
 * @param {Object} obj - 待拼接的对象
 * @returns {string} - 拼接成的请求字符串
 */
// @ts-ignore
export const encodeSearchParams = (obj) => {
  const params: any[] = [];
  Object.keys(obj).forEach((key) => {
    let value = obj[key];
    if (typeof value === 'undefined') {
      value = '';
    }
    params.push([key, encodeURIComponent(value)].join('='));
  });

  return params.join('&');
};
export const parseSearchParams = (url: string) => {
  let json = {};
  const arr = url.split('&');
  arr.forEach((item) => {
    const tmp = item.split('=');
    // @ts-ignore
    json[tmp[0]] = tmp[1];
  });
  return json;
};
