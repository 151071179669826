import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

export function Main() {
  return (
    <Container>
      <Suspense fallback={<div>Loading...</div>}>
        <Outlet />
      </Suspense>
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
  min-width: 1200px;
  overflow-x: auto;
`;
